@font-face {
  font-family: 'HeadingFont';
  src: local('HeadingFont'), url(../public/fonts/MarkPro.ttf) format('truetype');
}

html, body {
  /* overflow-x: hidden; */
  margin: 0;
  font-family: HeadingFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.root {
  overflow-x: hidden!important;
}

ul.contact-info > li a {
    color: #000;
    text-decoration: none;
}
ul.contact-info > li > svg {
    margin-right: 5px;
    color: #444444;
    font-size: 2rem;
}
ul.contact-info {
    line-height: 50px;
    font-size: 1rem;
}
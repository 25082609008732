
.bg-slider {
    background-color: #1f1f1f;
    /* height:698px; */
}

mark {
    font-weight: bold;
    /* border: 2px solid #000;
    background: #ff0!important;
    color: #12517d!important;
    font-size: 1.5rem!important;
    bottom: 0px;
    border-radius: 10px; */
}

.title{
    font-weight: 200px;
    font-size:2rem;
}

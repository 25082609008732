.service-bg-color {
    background-color: #ebebeb;
}

.service-bg-color .our-product-title {
    color: #000;
}

.service-bg-color p {
    font-family: HeadingFont;
}

.service-bg-color p > svg {
    color: #f90000;
}

.card {
    font-family: HeadingFont;
    background-color: transparent!important;
    color: #fff;
    box-shadow: unset!important;
    border: 0px!important;
}

.slick-track
{
    display: flex !important;
    justify-content: center!important; 	
    align-items: center!important;
}

.slick-slide
{
    height: inherit !important;
    justify-content: center!important; 	
    align-items: center!important;
}

.service-image-cover img {
    width: 100%;
}

@media  screen and (max-width:768px) {
    .icon{
        margin-bottom:12px;
    }
    
}
/* #image {
  height: 400px;
}

@media screen and (max-width: 430px) {
  #image {
    height: 300px;
  }
} */

.container-flex{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .container-flex{
    flex-direction: column;
  }
}

.column1{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex:1;
  margin: 20px;
}

.imgWrapper{
  max-width: 500px;
  /* width:500px; */
  max-height:450px;
  padding-bottom: 10px;
  border-radius: 20px;
  overflow: hidden;
}

.image{
  width: 100%;
  height: 450px;
  object-fit: cover;
}


@media screen and (max-width: 768px) {
  .image{
    width: 100%;
    height:350px;
  }
}

@media screen and (max-width: 427px) {
  .image{
    width: 100%;
    height:100%;
  }
}


.title{
  align-self: flex-start;
  padding-top: 20px;
  
}

.act-title{
  font-size:20px;
}

@media screen and (max-width: 430px) {
  .title{
    align-self: center;
  }
}

.subTitle{
  align-self: flex-start;
  color: #c9c9c9;
  max-width: 500px;
  
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1, h2, h3, h4 {
  font-family: HeadingFont!important;
}

h1 {
  font-weight: bold!important;
}

.amk-box-shadow {
  box-shadow: 0px 0px 20px #ccc;
}

.amk-p {
  line-height: 30px;
  font-size: 1.2rem;
  font-family: 'HeadingFont';
}

.amk-button {
  background-color: red;
}

.amk-title {
  font-weight: bold;
  color: #f90000!important;
}

.amk-card {
  background: #ddd;
}

button {
  border: 0px;
}

button:hover, button:active {
  border: 0;
  outline: unset;
}

@import url('https://fonts.googleapis.com/css?family=Carter+One');
.game-button {
  position: relative;
  top: 0;
  cursor: pointer;
  text-decoration: none !important;
  outline: none !important;
  font-family: 'Carter One', sans-serif;
  font-size: 20px;
  line-height: 1.5em;
  letter-spacing: .1em;
  text-shadow: 2px 2px 1px #0066a2, -2px 2px 1px #0066a2, 2px -2px 1px #0066a2, -2px -2px 1px #0066a2, 0px 2px 1px #0066a2, 0px -2px 1px #0066a2, 0px 4px 1px #004a87, 2px 4px 1px #004a87, -2px 4px 1px  #004a87;
  border: none;
  margin: 15px 15px 30px;
  background: repeating-linear-gradient( 45deg, #3ebbf7, #3ebbf7 5px, #45b1f4 5px, #45b1f4 10px);
  border-bottom: 3px solid rgba(16, 91, 146, 0.5);
  border-top: 3px solid rgba(255,255,255,.3);
  color: #fff !important;
  border-radius: 8px;
  padding: 8px 15px 10px;
  box-shadow: 0 6px 0 #266b91, 0 8px 1px 1px rgba(0,0,0,.3), 0 10px 0 5px #12517d, 0 12px 0 5px #1a6b9a, 0 15px 0 5px #0c405e, 0 15px 1px 6px rgba(0,0,0,.3);
}
.game-button:hover, .game-button:active {
  top:2px;
  box-shadow: 0 4px 0 #266b91, 0 6px 1px 1px rgba(0,0,0,.3), 0 8px 0 5px #12517d, 0 10px 0 5px #1a6b9a, 0 13px 0 5px #0c405e, 0 13px 1px 6px rgba(0,0,0,.3);
}
.game-button::before {
  content: '';
  height: 10%;
  position: absolute;
  width: 40%;
  background: #fff;
  right: 13%;
  top: 3%;
  border-radius: 99px;
  }
.game-button::after {
  content: '';
  height: 10%;
  position: absolute;
  width: 5%;
  background: #fff;
  right: 5%;
  top: 3%;
  border-radius: 99px;
}

/* CSS */
.button-24 {
  background: #F63333;
  border: 1px solid #F63333;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  min-width:140px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.button-24:hover,
.button-24:active {
  background-color: #fff;
  background-position: 0 0;
  color: #F63333;
}

.button-24:active {
  opacity: .5;
}

.header-style{
  background: #EE3D34;
  /* background-color: #3391D8; */
  color:#fff;
  display: inline-block;
  padding:12px 1.5rem;
  clip-path: polygon(100% 0,93% 50%,100% 99%,0% 100%,7% 50%,0% 0%);
}
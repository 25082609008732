.bg-partner {
    background-color: #fff;
    color: #000;
}

.bg-partner .our-product-title {
    color: #000;
}

.bg-partner .card-title {
    color: green!important;
}

mark {
    background: #ff0!important;
    color: #12517d!important;
    font-size: 1.5rem!important;
    bottom: 0px;
    border-radius: 10px;
}
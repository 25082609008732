.product-bg-color {
    background-color: #1f1f1f;
}

.our-product-title {
    color: #fff;
}

.card {
    font-family: HeadingFont;
    background-color: transparent!important;
    color: #fff;
    box-shadow: unset!important;
    border: 0px!important;
}

.card-subtitle {
    color: #F63333!important;
}

.slick-track
{
    display: flex !important;
    justify-content: center!important; 	
    align-items: center!important;
}

.slick-slide
{
    height: inherit !important;
    justify-content: center!important; 	
    align-items: center!important;
}



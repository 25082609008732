.main-logo {
  width: auto;
  height: 70px;
}

.amk-nav > a {
  text-transform: uppercase;
  font-family: HeadingFont;
  font-size: small;
  font-weight: 600;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px;
  padding: 5px 10px;
  text-decoration: none;
  color: #555;
  cursor: pointer;
}

.active,
.selected,
.amk-nav a:hover,
.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  background: #00000036 !important;
  color: #f90000 !important;
}

.mobileIcon {
  display: none;
}
@media screen and (max-width: 430px) {
  .mobileIcon {
    display: block;
    position: absolute;
    top: -5px;
    right: 0;
    transform: translate(-100%, 50%);
    font-size: 1.8rem;
    cursor: pointer;
  }
  .amk-nav > a {
    display: none;
  }
}
